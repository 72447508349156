import "./HomeSection4.scss";
import useTranslationCustom from "../../assets/image/community-section-1/useTranslationCustom";
import contactUs from "../../assets/image/home-section-4/contact-us.svg";
import partner01 from "../../assets/image/home-section-4/01.png";
import partner02 from "../../assets/image/home-section-4/02.png";
import partner03 from "../../assets/image/home-section-4/03.png";
import partner04 from "../../assets/image/home-section-4/04.png";
import partner05 from "../../assets/image/home-section-4/05.png";
import partner06 from "../../assets/image/home-section-4/06.png";
import partner07 from "../../assets/image/home-section-4/07.png";
import partner08 from "../../assets/image/home-section-4/08.png";
import partner09 from "../../assets/image/home-section-4/09.png";
import partner10 from "../../assets/image/home-section-4/10.png";
import partner11 from "../../assets/image/home-section-4/11.png";
import partner12 from "../../assets/image/home-section-4/12.png";
import partner13 from "../../assets/image/home-section-4/13.png";
import partner14 from "../../assets/image/home-section-4/14.png";
import partner15 from "../../assets/image/home-section-4/15.png";
import partner16 from "../../assets/image/home-section-4/16.png";
import partner17 from "../../assets/image/home-section-4/17.png";
import partner18 from "../../assets/image/home-section-4/18.png";
import partner19 from "../../assets/image/home-section-4/19.png";
import partner20 from "../../assets/image/home-section-4/20.png";
import partner21 from "../../assets/image/home-section-4/21.png";
import { useResponsive } from "view/styles/Responsive";
import { Swiper, SwiperSlide } from "swiper/react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Navigation, Pagination } from "swiper";

const partners = [
  partner01,
  partner02,
  partner03,
  partner04,
  partner05,
  partner06,
  partner07,
  partner08,
  partner09,
  partner10,
  partner11,
  partner12,
  partner13,
  partner14,
  partner15,
  partner16,
  partner17,
  partner18,
  partner19,
  partner20,
  partner21,
];

const companyEmail = process.env.REACT_APP_COMPANY_EMAIL;

function HomeSection4() {
  const { t } = useTranslationCustom({
    prefix: "section_4",
    nameSpace: "home",
  });

  const { isMobile } = useResponsive();

  const Front = useCallback(
    () => (
      <div className="home-section-4-front">
        <div className="home-section-4-height50 front">
          <div className="home-section-4-title">{t("title")}</div>
          <div className="home-section-4-sub-title">{t("sub_title")}</div>
          <div className="home-section-4-partners">
            {partners.map((el, index) => (
              <div className='home-section-4-partners-item'>
                <img src={el} alt="" key={`front-img-${index}`} />
              </div>
            ))}
          </div>
        </div>
      </div>
    ),
    [t, partners]
  );

  const Back = useCallback(
    () => (
      <div className="home-section-4-back">
        <div className="home-section-4-height50 back">
          <div className="home-section-4-title">{t("at_start_web3")}</div>
          <div className="home-section-4-sub-title">{t("business")}</div>
          <a
            className="home-section-4-contact-us"
            href={`mailto:${companyEmail}`}
          >
            {t("contact_us")}
            <img
              src={contactUs}
              alt={"contact us"}
              className="home-section-4-contact-us-icon"
            />
          </a>
        </div>
      </div>
    ),
    [t]
  );

  const [renderAfter, setRenderAfter] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setRenderAfter(true);
    }, 400);
  }, []);

  return (
    <div className={"home-section-4"}>
      {renderAfter && (
        <>
          {!isMobile && (
            <>
              <Front />
              <Back />
            </>
          )}
          {isMobile && (
            <Swiper
              pagination={{
                dynamicBullets: false,
                dynamicMainBullets: 1,
              }}
              speed={500}
              allowTouchMove={true}
              modules={[Navigation, Pagination]}
              className={"home-section-4-swiper"}
            >
              <SwiperSlide>
                <Front />
              </SwiperSlide>
              <SwiperSlide>
                <Back />
              </SwiperSlide>
            </Swiper>
          )}
        </>
      )}
    </div>
  );
}

export default HomeSection4;
